import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import BasketContext from '../../context/basket-context';
import { counties } from '../../data/counties';
import { makeOrder } from '../../services/order';
import { showInfo, showWarning } from '../../services/toast';
import { BASKET_CONSTANTS } from '../../shared/constants/basket-constants';

export const BillingDetails = () => {
  const { basket, dispatch } = useContext(BasketContext);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const gtagActive =
    window.dataLayer && window.dataLayer.some((obj) => obj.event === 'gtm.js');

  const orderTitle = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Sir'];

  const pushEventToGTAG = (orderId) => {
    typeof window !== 'undefined' &&
      window.dataLayer.push({
        event: 'ga-event',
        eventCategory: 'Ecommerce',
        eventAction: 'Order confirmation',
        eventLabel: orderId,
      });
  };

  const submitFormHandler = (data) => {
    setLoading(true);

    const order = {
      customer: data,
      basket,
    };

    makeOrder(order)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: BASKET_CONSTANTS.CLEAR_BASKET,
          });
          navigate('/');
          showInfo('Your order is placed.');

          if (gtagActive) {
            pushEventToGTAG(response.data.orders.create.id);
          }
        } else {
          showWarning('Something went wrong. Your order is not placed.');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="billing-details">
      <Form onSubmit={handleSubmit(submitFormHandler)}>
        <Row>
          <Col md={4} className="mb-3">
            <Form.Group controlId="form_order_title">
              <Form.Label>Order Title</Form.Label>
              <Form.Control
                as="select"
                required
                defaultValue=""
                className="form-control"
                aria-label="Please select"
                {...register('title')}
              >
                <option value="" hidden disabled>
                  Please Select
                </option>
                {orderTitle.map((title, index) => {
                  return (
                    <option value={title} key={index}>
                      {title}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form_first_name">
              <Form.Label>First name*</Form.Label>
              <Form.Control required type="text" {...register('firstName')} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form_surname">
              <Form.Label>Surname*</Form.Label>
              <Form.Control required type="text" {...register('surname')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_email">
              <Form.Label>Email*</Form.Label>
              <Form.Control required type="email" {...register('email')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_company_name">
              <Form.Label>Company Name*</Form.Label>
              <Form.Control required type="text" {...register('companyName')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_address_1">
              <Form.Label>Address line 1*</Form.Label>
              <Form.Control
                required
                type="text"
                {...register('addressLine1')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_address_2">
              <Form.Label>Address line 2</Form.Label>
              <Form.Control type="text" {...register('addressLine2')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_city_town">
              <Form.Label>City or town*</Form.Label>
              <Form.Control required type="text" {...register('cityOrTown')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_county">
              <Form.Label>County*</Form.Label>
              <Form.Control
                as="select"
                required
                defaultValue=""
                className="form-control"
                aria-label="Default select example"
                {...register('county')}
              >
                <option value="" hidden disabled>
                  Please Select
                </option>
                {counties.map((county, index) => {
                  return (
                    <option
                      disabled={county.includes('---')}
                      value={county}
                      key={index}
                    >
                      {county}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_postcode">
              <Form.Label>Postcode*</Form.Label>
              <Form.Control required type="text" {...register('postcode')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Form.Group controlId="form_instructions">
              <Form.Label>Instructions</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register('instructions')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Button
              type="submit"
              className="float-right grow"
              disabled={isLoading}
            >
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fa mr-1"
                  aria-hidden="true"
                />
              )}
              Place Order
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
