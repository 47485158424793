export const counties = [
  '--- England ---',
  'Bath and North East Somerset',
  'Bedford',
  'Blackburn with Darwen',
  'Blackpool',
  'Bournemouth',
  'Bracknell Forest',
  'Brighton &amp; Hove',
  'Bristol',
  'Buckinghamshire',
  'Cambridgeshire',
  'Central Bedfordshire',
  'Cheshire East',
  'Cheshire West and Chester',
  'Cornwall',
  'County',
  'County Durham',
  'Cumbria',
  'Darlington',
  'Derby',
  'Derbyshire',
  'Devon',
  'Dorset',
  'East Riding of Yorkshire',
  'East Sussex',
  'Essex',
  'Gloucestershire',
  'Greater London',
  'Greater Manchester',
  'Halton',
  'Hampshire',
  'Hartlepool',
  'Herefordshire',
  'Hertfordshire',
  'Hull',
  'Isle of Wight',
  'Isles of Scilly',
  'Kent',
  'Lancashire',
  'Leicester',
  'Leicestershire',
  'Lincolnshire',
  'Luton',
  'Medway',
  'Merseyside',
  'Middlesbrough',
  'Milton Keynes',
  'Norfolk',
  'North East Lincolnshire',
  'North Lincolnshire',
  'North Somerset',
  'North Yorkshire',
  'Northamptonshire',
  'Northumberland',
  'Nottingham',
  'Nottinghamshire',
  'Oxfordshire',
  'Peterborough',
  'Plymouth',
  'Poole',
  'Portsmouth',
  'Reading',
  'Redcar and Cleveland',
  'Rutland',
  'Shropshire',
  'Slough',
  'Somerset',
  'South Gloucestershire',
  'South Yorkshire',
  'Southampton',
  'Southend-on-Sea',
  'Staffordshire',
  'Stockton-on-Tees',
  'Stoke-on-Trent',
  'Suffolk',
  'Surrey',
  'Swindon',
  'Telford and Wrekin',
  'Thurrock',
  'Torbay',
  'Tyne and Wear',
  'Warrington',
  'Warwickshire',
  'West Berkshire',
  'West Midlands',
  'West Sussex',
  'West Yorkshire',
  'Wiltshire',
  'Windsor and Maidenhead',
  'Wokingham',
  'Worcestershire',
  'York',
  '--- Northern Ireland ---',
  'Antrim',
  'Armagh',
  'City of Belfast',
  'Down',
  'Fermanagh',
  'Londonderry',
  'City of Derry',
  'Tyrone',
  '--- Scotland ---',
  'City of Aberdeen',
  'Aberdeenshire',
  'Angus (Forfarshire)',
  'Argyll',
  'Ayrshire',
  'Banffshire',
  'Berwickshire',
  'Bute',
  'Caithness',
  'Clackmannanshire',
  'Cromartyshire',
  'Dumfriesshire',
  'Dunbartonshire (Dumbarton)',
  'City of Dundee',
  'East Lothian (Haddingtonshire)',
  'City of Edinburgh',
  'Fife',
  'City of Glasgow',
  'Inverness-shire',
  'Kincardineshire',
  'Kinross-shire',
  'Kirkcudbrightshire',
  'Lanarkshire',
  'Midlothian',
  'Moray (Elginshire)',
  'Nairnshire',
  'Orkney',
  'Peeblesshire',
  'Perthshire',
  'Renfrewshire',
  'Ross and Cromarty',
  'Ross-shire',
  'Roxburghshire',
  'Selkirkshire',
  'Shetland (Zetland)',
  'Stirlingshire',
  'Sutherland',
  'West Lothian',
  'Wigtownshire',
  '--- Wales ---',
  'Anglesey',
  'Brecknockshire',
  'Caernarfonshire',
  'Cardiganshire',
  'Carmarthenshire',
  'Clwyd',
  'Denbighshire',
  'Dyfed',
  'Flintshire',
  'Glamorgan',
  'Gwent',
  'Gwynedd',
  'Merionethshire',
  'Mid Glamorgan',
  'Monmouthshire',
  'Montgomeryshire',
  'Pembrokeshire',
  'Powys',
  'Radnorshire',
  'South Glamorgan',
  'West Glamorgan',
  'Wrexham',
];
