import axios from 'axios';

const postUrl = process.env.GATSBY_CLOUDFLARE_WORKER_URL;

const getCustomerData = (customer) => {
  const customerIdentifier = `${customer.title}_${customer.firstName}_${customer.surname}`;

  return {
    firstName: customer.firstName,
    lastName: customer.surname,
    identifier: customerIdentifier,
    addresses: [
      {
        type: 'billing',
        street: customer.addressLine1,
        street2: customer.addressLine2,
        postalCode: customer.postcode,
        city: customer.cityOrTown,
        state: customer.county,
        email: customer.email,
      },
    ],
  };
};

const getBasketItemsFromOrder = (basket) => {
  return basket.map((item) => ({
    productId: item.id,
    name: item.name,
    sku: item.code,
    imageUrl: item.image,
    price: {
      currency: 'GBP',
      net: 0,
      gross: 0,
    },
    quantity: item.quantity,
  }));
};

const getCustomFields = (customer) => {
  return [
    {
      key: 'Title',
      value: customer.title,
    },
    {
      key: 'Company Name',
      value: customer.companyName,
    },
  ];
};

const getMutationQueryFromOrder = () => {
  return `
    mutation createOrder(
      $customer: CustomerInput!,
      $cart: [OrderItemInput!]!,
      $additionalInformation: String,
      $meta: [OrderMetadataInput!]!) {
        orders {
          create(
            input: {
              customer: $customer
              cart: $cart
              additionalInformation: $additionalInformation
              meta: $meta
            }
          ) {
            id
          }
        }
    }
    `;
};

const makeOrder = (order) => {
  const postBody = {
    query: getMutationQueryFromOrder(),
    variables: {
      customer: getCustomerData(order.customer),
      cart: getBasketItemsFromOrder(order.basket),
      additionalInformation: order.customer.instructions,
      meta: getCustomFields(order.customer),
    },
  };

  return axios.post(postUrl, postBody, {}).then((response) => {
    // First data is from axios, second is from Crystallize. Therefore, removing the second (and not needed) extra data variable.
    response.data = response.data.data;
    return response;
  });
};

export { makeOrder };
