import React, { useContext } from 'react';
import { Link } from 'gatsby';

import BasketContext from '../../context/basket-context';

export const Summary = () => {
  const { basket } = useContext(BasketContext);

  const countItems = () => {
    return basket.length;
  };

  return (
    <aside>
      <h5 className="mb-4 font-weight-bold">Summary</h5>
      <ul className="list-group list-group-flush pb-4">
        {basket.map((product) => {
          return (
            <li
              className="list-group-item d-flex justify-content-between align-items-center px-0"
              key={product.code}
            >
              {product.quantity}x {product.name} ({product.code})
            </li>
          );
        })}

        <li className="list-group-item d-flex justify-content-between align-items-center px-0 font-weight-bold">
          Total Items
          <span>{countItems()}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center px-0">
          Expected Dispatch
          <span>Next Working Day</span>
        </li>
      </ul>

      <Link
        to="/basket"
        className="btn btn-secondary btn-block mb-2"
        role="button"
      >
        Edit basket
      </Link>
    </aside>
  );
};
