import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Header } from '../layout/header';
import { Footer } from '../layout/footer';
import { HeroPrimary } from '../components/hero-primary/';
import { SectionHeading } from '../components/section-heading/';
import { Logos } from '../components/logos/';
import { BillingDetails } from '../components/checkout/billing-details';
import { Summary } from '../components/checkout/summary';
import CookieConsent from '../layout/cookie-consent';
import BasketContext from '../context/basket-context';

export default function Checkout() {
  const { basket } = useContext(BasketContext);

  const showBasketEmptyMessage = () => {
    return basket.length === 0;
  };

  return (
    <Container fluid className="p-0">
      <Header />
      <CookieConsent />
      <HeroPrimary heroTitle="Checkout" />
      <SectionHeading sectionHeading="Complete your order" />
      <Container>
        <Row className="justify-content-center mt-5 mb-4">
          <Col lg={8} className="bg-white p-4">
            {showBasketEmptyMessage() ? (
              <p>Your basket is empty. Please add items to your basket.</p>
            ) : (
              <BillingDetails />
            )}
          </Col>
          <Col lg={4}>
            <Summary />
          </Col>
        </Row>
      </Container>
      <Logos />
      <Footer />
    </Container>
  );
}
