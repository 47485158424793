import React from 'react';
import { Container, Row } from 'react-bootstrap';

import './section-heading.scss';

export const SectionHeading = (props) => (
  <div className="section-heading">
    <Container>
      <Row className="row justify-content-center">
        <h2 className="font-weight-extra-bold dark-grey-text text-center">
          {props.sectionHeading}
        </h2>
      </Row>
    </Container>
  </div>
);
